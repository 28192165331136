// export const environment = {
//   production: false,
//   baseUrl: 'https://app-uat.statelink.net/',
//   legacyStatelinkUrl: 'https://stateside-newuat.statelink.net',
//   loginUrl: "https://stateside-newuat.statelink.net/login",
//   logoutUrl: "https://stateside-newuat.statelink.net/logout",
//   apiUrl: 'https://api-uat.statelink.net',
//   cookieDomainUrl: 'statelink.net',
//   env: 'local',
//   version: '0.0.1', // uat.qa.dev
// };

// export const environment = {
//   production: false,
//   baseUrl: 'https://app-uat.statelink.net/',
//   legacyStatelinkUrl: 'https://app-uat.statelink.net',
//   loginUrl: "https://app-uat.statelink.net/login",
//   logoutUrl: "https://app-uat.statelink.net/logout",
//   apiUrl: 'https://api-uat.statelink.net',
//   cookieDomainUrl: 'statelink.net',
//   env: 'local',
//   version: '1.0.6', // uat.qa.dev
// };

export const environment = {
  production: false,
  baseUrl: 'https://app.statelink.uatbyopeneyes.com/',
  legacyStatelinkUrl: 'https://statelink.uatbyopeneyes.com',
  loginUrl: "https://statelink.uatbyopeneyes.com/login",
  logoutUrl: "https://statelink.uatbyopeneyes.com/logout",
  apiUrl: 'https://api.statelink.uatbyopeneyes.com',
  cookieDomainUrl: 'statelink.uatbyopeneyes.com',
  env: 'local',
  version: '1.0.6', // uat.qa.dev
};